import { FC, createRef, memo } from 'react';
import styles from '../../../ExpensionMap.module.scss';
import cx from 'classnames';
import { useExpansionMapHelper } from '../../../helper';
import { Districts } from '../../../../../constants/districts';

export const Meschenich: FC = memo(() => {
  const ref = createRef<SVGTextElement>();
  const {
    district,
    expansionStatusClass,
    isSelected,
    handleClick,
    handleHover,
  } = useExpansionMapHelper({
    district: Districts.Meschenich,
    ref,
  });

  return (
    <>
      <g
        className={cx(styles.districtWrapper, {
          [styles.isSelected]: isSelected,
        })}
        id={district}
        onClick={handleClick}
        onMouseEnter={handleHover}
      >
        <path
          className={cx(styles[expansionStatusClass], styles.districtArea)}
          aria-label="Meschenich"
          d="M442,934l54,17.1v78.2c-2.4,0.6-9.9,1.3-11.8,2.9c-0.6,1.9,4.5,10.6,5,12.1c0,0,3.5,4.8,3.5,4.8l-2.4,1.2 l1.4,3.5l-0.2,0.1l-3,1.5H485c0,0.4,0.1,1.3,0.2,1.7c-6.6,0.4-14.6,4.1-21.3,3.7c0.7-0.7,1.5-2.1,1.9-3l-3.6-13.2 c-0.7,0.1-5.2,0.9-5.8,0.6c0-0.7,1.6-10,1.7-11l-9.2,1.4c-0.5-3.2-1.7-10.4-2.2-13.6c0.1-0.2,0.4-0.6,0.5-0.8 c-0.6-2-1.4-5.7-2.1-7.7l-9.1,0.2c0.1-1.3,0.6-6.1,0.8-7.5l-4.2,1.8c1.6-3.5,3.9-8.8,5.4-12.3c-0.2-0.1-0.5-0.3-0.7-0.3l-1-5.4 c-0.2,0-0.5,0-0.6,0.1c-0.4-1.5-1.8-6.8-2.1-8.2l-3,0.8c0-0.2,0-0.5-0.1-0.7l-1.6,0.4c-1.5-9.7-2.8-9.9,1.1-19.4 c4.7-5.4,8-17.4,15-19.4C444,940.8,442.7,936.9,442,934z"
        />
        <text
          className={styles.districtName}
          ref={ref}
          transform="matrix(1 0 0 1 441.717 970.7932)"
        >
          <tspan x="0" y="0">
            Mesche-{' '}
          </tspan>
          <tspan x="0" y="14.4">
            nich
          </tspan>
        </text>
      </g>
    </>
  );
});
